import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  close(e) {
    e.preventDefault();
    this.containerTarget.style.display = "none";
  }

  disconnect() {
    this.containerTarget.style.display = "none";
  }
}
